<template>
  <main>
    <section class="vh-100 d-flex align-items-center justify-content-center">
      <div class="container">
        <div class="row">
          <div
            class="col-12 text-center d-flex align-items-center justify-content-center"
          >
            <div>
              <router-link to="/">
                <img
                  class="img-fluid w-75"
                  src="@/assets/img/illustrations/404.svg"
                  alt="404 not found"
                />
              </router-link>
              <h1 class="mt-5">
                Page not
                <span class="font-weight-bolder text-primary">found</span>
              </h1>
              <p class="lead my-4">
                Oops! Looks like you followed a bad link. If you think this is a
                problem with us, please tell us.
              </p>
              <router-link class="btn btn-primary animate-hover" to="/"
                ><i class="fas fa-chevron-left mr-3 pl-2 animate-left-3"></i>Go
                back home
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "404"
};
</script>

<style scoped></style>
